<template>
  <div class="row">
    <div class="col-12 col-lg-6 c h">
        <div class="card">
            <div class="card-header">
                <h5>
                    توزيع على الحافلات
                </h5>
            </div>
            <div class="card-footer">
                <div class="form-group">
                  <label for="">الحملة</label>
                  <select class="form-control" v-model="hamla" @change="getBusses()">
                    <option value="1">البشائر</option>
                    <option value="2">الميسر</option>
                  </select>
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-primary" @click="arrangeNow()">
                        توزيع الان
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 g">
        <div class="card">
            <div class="card-header">
                توزيع الحجاج في 
                الحافلات
            </div>
            <div class="card-body">
                <app-collapse>
                    <app-collapse-item
                    v-for="bus in busses"
                    :key="bus._id"
                    :title="bus.title + `, ${bus.mix ? 'عوائل' : 'رجال'} (${bus.users.length} حاج من ${bus.limit})`"
                    >
                    <div class="g">
                        <button class="btn btn-sm btn-primary" style="border-radius: 0px" @click="print(bus._id, bus.title, `<table>
                            <tbody>
                                <tr>
                                    <td>
                                        السائق
                                    </td>
                                    <td>
                                        جوال
                                        السائق
                                    </td>
                                    <td>
                                        المساعد
                                    </td>
                                    <td>
                                        جوال
                                        المساعد
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        ${bus.driver_name}
                                    </td>
                                    <td>
                                        ${bus.driver_phone}
                                    </td>
                                    <td>
                                        ${bus.helper_name}
                                    </td>
                                    <td>
                                        ${bus.helper_phone}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        الدليل
                                    </td>
                                    <td>
                                        جوال
                                        الدليل
                                    </td>
                                    <td>
                                        المشرف
                                    </td>
                                    <td>
                                        جوال
                                        المشرف
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        ${bus.guide_name}
                                    </td>
                                    <td>
                                        ${bus.guide_phone}
                                    </td>
                                    <td>
                                        ${bus.admin_name}
                                    </td>
                                    <td>
                                        ${bus.admin_phone}
                                    </td>
                                </tr>
                            </tbody>
                            </table>`)">
                            <i class="fa fa-print"></i>
                            طباعة كشف الحجاج
                        </button>
                    </div>
                    <div class="col-12 table-responsive" :class="'print_' + bus._id">
                        <table class="table table-sm table-bordered table-hover">
                            <thead>
                                <th class="dnone">
                                    م
                                </th>
                                <th>
                                    الاسم
                                </th>
                                <th>
                                    رقم الهوية
                                </th>
                                <th>
                                    الهاتف
                                </th>
                                <th>
                                    رقم الحجز
                                </th>
                                <th>
                                    نقطة الانطلاق
                                </th>
                                <th>
                                    الجنسية
                                </th>
                            </thead>
                            <tbody>
                                <tr v-for="(u, i) in bus.users" :key="u._id">
                                    <td class="dnone">
                                        {{ i+1 }}
                                    </td>
                                    <td>
                                        {{ u.name }}
                                    </td>
                                    <td>
                                        {{ u.number }}
                                    </td>
                                    <td>
                                        {{ u.phone }}
                                    </td>
                                    <td>
                                        {{ u.order_id }}
                                    </td>
                                    <td>
                                        {{ u.start_location }}
                                    </td>
                                    <td>
                                        {{ u.national }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </app-collapse-item>
                </app-collapse>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
    directives: {
        Ripple,
    },
    components: {
        AppCollapse,
        AppCollapseItem
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            busses: [],
            hamla: "1"
        }
    },
    created(){
        if(!checkPer('arrange')){
            this.$router.push('/pers')
            return;
        }
        this.getBusses()
    },
    methods: {
        arrangeNow(){
            var g = this;
            if(confirm("متأكد من اعادة التوزيع؟\nسوف يتم الغاء اي تعديلات يدوية سابقة.")){
                $.post(api + '/admin/busses/arrange', {
                    jwt: g.user.jwt,
                    hamla: g.hamla
                }).then(function(r){
                    g.getBusses();
                })
            }
        },
        getBusses(){
            var g = this;
            $.post(api + '/admin/busses/list-with-users', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.busses = r.response;
            })
        },
        print(id, title, footer){
            var divToPrint = $(".print_" + id);
            var newWin= window.open("");
            newWin.document.write(`
            <style>
            table, td, th {
                border: 1px solid;
            }
            table {
                width: 100%;
                border-collapse: collapse;
            }
            html,body{
                direction: rtl
            }
            tr:nth-of-type(33n){
                page-break-after: always;
            }
            .x{
                position: relative;
                top: 100px
            }
            td:nth-child(2){
                text-align: right !important
            }
            th,td{
                padding-right: 3px;
                padding-left: 3px;
                text-align: center
            }
        .h5{
            position: absolute;
            top: -50px;
            right: 10px
        }
            .banner{
                width: 100%;
                position: fixed;
                top: 0px
            }
            .footer{
                width: 100%;
                position: fixed;
                bottom: 0px
            }
            tr:nth-child(even) {
                background-color: #f2f2f2;
            }
            @media print and (orientation: portrait) {
                .banner2{
                    display: none
                }
                .footer2{
                    display: none
                }
            }
            @media print and (orientation: landscape) {

            tr:nth-of-type(20n){
                page-break-after: always;
            }
                .banner{
                    display:none
                }
                .footer{
                    display: none
                }
                .banner2{
                    width: 100%;
                    position: fixed;
                    top: 0px
                }
                .footer2{
                    width: 100%;
                    position: fixed;
                    bottom: 0px
                }
            }
            </style>
            `)
            newWin.document.write(`
                <img class='banner' src='images/print-banner.png'>
                <img class='banner2' src='images/land-banner.png'>
                <div class='x'>
            <h5 class='h5'>كشف الحجاج للحافلة ${title}</h5>${divToPrint.html()}
            <h5 class='footerx'>${footer?.replaceAll('undefined', '')}</h5></div>
                <img class='footer' src='images/print-footer.png'>
                <img class='footer2' src='images/land-footer.png'>
            `);
            newWin.document.close()
            newWin.print();
        }
    }
}
</script>